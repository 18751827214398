<template>
  <section style="margin-top:-58px">
    <v-container fluid class="pa-0">
      <v-layout v-scroll="onScroll" row wrap>
        <v-flex xs12>
          <v-parallax
            :src="this.$store.state.brand.brand.city_splash_primary"
            class="first"
            height="700"
          >
            <v-container fill-height>
              <v-flex align-center column hidden-xs-only>
                <v-flex xs12 sm9 md9 mb-4 class="hero-container">
                  <!-- Hero: Edición de Título -->
                  <template
                    v-if="
                      $auth && $auth.user && $auth.user.type === 'superadmin'
                    "
                  >
                    <h2
                      v-if="
                        editedTexts.heroSection.title.trim().length === 0 &&
                          !editTitle
                      "
                      class="white--text display-3 link"
                      @click="toggleInput('editTitle', 'title-input')"
                    >
                      Agrega un Título
                    </h2>
                    <h2
                      v-if="
                        editedTexts.heroSection.title.trim().length !== 0 &&
                          !editTitle
                      "
                      class="white--text display-3 edit"
                      @click="toggleInput('editTitle', 'title-input')"
                    >
                      {{ editedTexts.heroSection.title }}
                    </h2>
                    <v-text-field
                      v-show="editTitle"
                      ref="title-input"
                      v-model="editedTexts.heroSection.title"
                      solo
                      dense
                      dark
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="saveHero"
                      @blur="saveHero"
                    />
                  </template>
                  <template v-else>
                    <h2 class="white--text display-3">
                      {{ editedTexts.heroSection.title }}
                    </h2>
                  </template>
                  <!-- Hero: Edición de Subtitulo-->
                  <template
                    v-if="
                      $auth && $auth.user && $auth.user.type === 'superadmin'
                    "
                  >
                    <h3
                      v-if="
                        editedTexts.heroSection.subtitle.trim().length === 0 &&
                          !editSubtitle
                      "
                      class="white--text display-1 link"
                      @click="toggleInput('editSubtitle', 'subtitle-input')"
                    >
                      Agrega un Subtítulo
                    </h3>
                    <h3
                      v-if="
                        editedTexts.heroSection.subtitle.trim().length !== 0 &&
                          !editSubtitle
                      "
                      class="white--text display-1 edit"
                      @click="toggleInput('editSubtitle', 'subtitle-input')"
                    >
                      {{ editedTexts.heroSection.subtitle }}
                    </h3>
                    <v-text-field
                      v-show="editSubtitle"
                      ref="subtitle-input"
                      v-model="editedTexts.heroSection.subtitle"
                      solo
                      dense
                      dark
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="saveHero"
                      @blur="saveHero"
                    />
                  </template>
                  <template v-else>
                    <h3 class="white--text display-1">
                      {{ editedTexts.heroSection.subtitle }}
                    </h3>
                  </template>
                  <v-progress-linear
                    color="primary"
                    height="2"
                    value="15"
                    background-color="white"
                  ></v-progress-linear>
                  <!-- Hero: Edición de Second Subtitulo-->
                  <template
                    v-if="
                      $auth && $auth.user && $auth.user.type === 'superadmin'
                    "
                  >
                    <h3
                      v-if="
                        editedTexts.heroSection.second_subtitle.trim()
                          .length === 0 && !editSecondSubtitle
                      "
                      class="white--text title link"
                      @click="
                        toggleInput(
                          'editSecondSubtitle',
                          'second_subtitle-input'
                        )
                      "
                    >
                      Agrega un Segundo Subtítulo
                    </h3>
                    <h3
                      v-if="
                        editedTexts.heroSection.second_subtitle.trim()
                          .length !== 0 && !editSecondSubtitle
                      "
                      class="white--text title edit"
                      @click="
                        toggleInput(
                          'editSecondSubtitle',
                          'second_subtitle-input'
                        )
                      "
                    >
                      {{ editedTexts.heroSection.second_subtitle }}
                    </h3>
                    <v-text-field
                      v-show="editSecondSubtitle"
                      ref="second_subtitle-input"
                      v-model="editedTexts.heroSection.second_subtitle"
                      solo
                      dense
                      dark
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="saveHero"
                      @blur="saveHero"
                    />
                  </template>
                  <template v-else>
                    <h3 class="white--text title">
                      {{ editedTexts.heroSection.second_subtitle }}
                    </h3>
                  </template>
                </v-flex>
                <v-layout column class="hero-container">
                  <v-flex xs12>
                    <!-- Hero: Edición Certificaten title -->
                    <template
                      v-if="
                        $auth && $auth.user && $auth.user.type === 'superadmin'
                      "
                    >
                      <h3
                        v-if="
                          editedTexts.heroSection.certificate_title.trim()
                            .length === 0 && !editcertificate_title
                        "
                        class="white--text display-1 link"
                        @click="
                          toggleInput(
                            'editcertificate_title',
                            'certificate_title-input'
                          )
                        "
                      >
                        Agrega un Segundo Subtítulo
                      </h3>
                      <h3
                        v-if="
                          editedTexts.heroSection.certificate_title.trim()
                            .length !== 0 && !editcertificate_title
                        "
                        class="white--text display-1 edit"
                        @click="
                          toggleInput(
                            'editcertificate_title',
                            'certificate_title-input'
                          )
                        "
                      >
                        {{ editedTexts.heroSection.certificate_title }}
                      </h3>
                      <v-text-field
                        v-show="editcertificate_title"
                        ref="certificate_title-input"
                        v-model="editedTexts.heroSection.certificate_title"
                        solo
                        dense
                        dark
                        @keydown.enter.exact.prevent
                        @keyup.enter.exact="saveHero"
                        @blur="saveHero"
                      />
                    </template>
                    <template v-else>
                      <h3 class="white--text display-1">
                        {{ editedTexts.heroSection.certificate_title }}
                      </h3>
                    </template>
                  </v-flex>
                  <v-layout justify-space-between>
                    <v-flex xs12 sm12 md9>
                      <v-form
                        ref="formCertificate"
                        v-model="validCertificate"
                        lazy-validation
                        @submit.prevent="viewCertificate"
                      >
                        <v-layout row wrap mt-4>
                          <v-flex xs12 sm9 md8 xl10 text-xs-center>
                            <v-text-field
                              v-model="selectedHash"
                              class="input-search"
                              solo
                              :rules="rules"
                              placeholder="Ingresa la llave del certificado"
                              required
                              hide-details
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm3 md4 xl2 text-xs-center>
                            <v-btn
                              :disabled="!selectedHash"
                              class="btn-search ma-0"
                              color="primary"
                              type="submit"
                              :class="{ 'see-certificate': !selectedHash }"
                              :style="!selectedHash ? '' : 'opacity: 1'"
                            >
                              Ver certificado
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-flex>
              <v-flex align-center column justify-center hidden-sm-and-up>
                <v-flex xs12>
                  <h2 class="white--text display-1">
                    Certificados en Blockchain
                  </h2>
                  <v-progress-linear
                    color="primary"
                    height="2"
                    value="15"
                    background-color="white"
                  ></v-progress-linear>
                  <h3 class="white--text headline">
                    Certificación de obras de arte
                  </h3>
                </v-flex>
                <v-layout column class="hero-container">
                  <v-flex xs12>
                    <h3 class="white--text display-1">
                      Encuentra tu certificado
                    </h3>
                  </v-flex>
                  <v-layout column justify-space-between>
                    <v-flex xs12 sm12 md9>
                      <v-form
                        ref="formCertificate"
                        v-model="validCertificate"
                        lazy-validation
                        @submit.prevent="viewCertificate"
                      >
                        <v-layout row wrap mt-4>
                          <v-flex xs12 sm9 md8 xl10 text-xs-center>
                            <v-text-field
                              v-model="selectedHash"
                              class="input-search"
                              solo
                              :rules="rules"
                              placeholder="Ingresa la llave del certificado"
                              required
                              hide-details
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm3 md4 xl2 mt-4 text-xs-center>
                            <v-btn
                              :disabled="!selectedHash"
                              class="btn-search ma-0"
                              color="primary"
                              type="submit"
                              :class="{ 'see-certificate': !selectedHash }"
                              :style="!selectedHash ? '' : 'opacity: 1'"
                            >
                              Ver certificado
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-container>
          </v-parallax>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container class="container-info__columns">
      <v-layout row wrap>
        <v-flex xs12 mt-5>
          <v-layout align-center>
            <v-flex xs12 text-xs-center>
              <h1 class="grey--text text--darken-3">
                <!-- ColumnSection: Edición de Slogan -->
                <template
                  v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
                >
                  <span
                    v-if="
                      editedTexts.columnsSection.slogan.trim().length === 0 &&
                        !editCitySlogan
                    "
                    class="font-weight-light link"
                    @click="toggleInput('editCitySlogan', 'city-slogan-input')"
                  >
                    Agrega el slogan de la Ciudad
                  </span>
                  <span
                    v-if="
                      editedTexts.columnsSection.slogan.trim().length !== 0 &&
                        !editCitySlogan
                    "
                    class="font-weight-light edit"
                    @click="toggleInput('editCitySlogan', 'city-slogan-input')"
                  >
                    {{ editedTexts.columnsSection.slogan }}
                  </span>
                  <v-text-field
                    v-show="editCitySlogan"
                    ref="city-slogan-input"
                    v-model="editedTexts.columnsSection.slogan"
                    solo
                    dense
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="saveColumns"
                    @blur="saveColumns"
                  />
                </template>
                <template v-else>
                  <span class="font-weight-light">
                    {{ editedTexts.columnsSection.slogan }}
                  </span>
                </template>
              </h1>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-between class="mt-5">
            <v-flex xs12 md3>
              <h2 class="text-xs-center mb-3">
                <v-icon size="48" color="primary">
                  $vuetify.icons.oscity_settings
                </v-icon>
              </h2>
              <!-- ColumnSection: Edición de Titulo e Info Columna 1 -->
              <template
                v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
              >
                <h4
                  v-if="
                    editedTexts.columnsSection.title_column1.trim().length ===
                      0 && !edittitle_column1
                  "
                  class="primary--text text-xs-center mb-3 link"
                  @click="
                    toggleInput('edittitle_column1', 'city-title_column1-input')
                  "
                >
                  Agrega el título de esta columna de la Ciudad
                </h4>
                <h4
                  v-if="
                    editedTexts.columnsSection.title_column1.trim().length !==
                      0 && !edittitle_column1
                  "
                  class="primary--text text-xs-center mb-3 edit"
                  @click="
                    toggleInput('edittitle_column1', 'city-title_column1-input')
                  "
                >
                  {{ editedTexts.columnsSection.title_column1 }}
                </h4>
                <v-text-field
                  v-show="edittitle_column1"
                  ref="city-title_column1-input"
                  v-model="editedTexts.columnsSection.title_column1"
                  solo
                  dense
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="saveColumns"
                  @blur="saveColumns"
                />
              </template>
              <template v-else>
                <h4 class="primary--text text-xs-center mb-3">
                  {{ editedTexts.columnsSection.title_column1 }}
                </h4>
              </template>
              <template
                v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
              >
                <p
                  v-if="
                    editedTexts.columnsSection.info_column1.trim().length ===
                      0 && !editinfo_column1
                  "
                  class="subheading text-xs-center link"
                  @click="
                    toggleInput('editinfo_column1', 'city-infocol1-input')
                  "
                >
                  Agrega la información de ésta columna de la Ciudad
                </p>
                <p
                  v-if="
                    editedTexts.columnsSection.info_column1.trim().length !==
                      0 && !editinfo_column1
                  "
                  class="subheading text-xs-center edit"
                  @click="
                    toggleInput('editinfo_column1', 'city-infocol1-input')
                  "
                >
                  {{ editedTexts.columnsSection.info_column1 }}
                </p>
                <v-text-field
                  v-show="editinfo_column1"
                  ref="city-infocol1-input"
                  v-model="editedTexts.columnsSection.info_column1"
                  solo
                  dense
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="saveColumns"
                  @blur="saveColumns"
                />
              </template>
              <template v-else>
                <p class="subheading text-xs-center">
                  {{ editedTexts.columnsSection.info_column1 }}
                </p>
              </template>
            </v-flex>
            <v-flex xs12 md3>
              <h2 class="text-xs-center mb-3">
                <v-icon size="48" color="primary">
                  $vuetify.icons.oscity_edit
                </v-icon>
              </h2>
              <!-- ColumnSection: Edición de Titulo e InfoColumna 2 -->
              <template
                v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
              >
                <h4
                  v-if="
                    editedTexts.columnsSection.title_column2.trim().length ===
                      0 && !edittitle_column2
                  "
                  class="primary--text text-xs-center mb-3 link"
                  @click="
                    toggleInput('edittitle_column2', 'city-title_column2-input')
                  "
                >
                  Agrega el título de esta columna de la Ciudad
                </h4>
                <h4
                  v-if="
                    editedTexts.columnsSection.title_column2.trim().length !==
                      0 && !edittitle_column2
                  "
                  class="primary--text text-xs-center mb-3 edit"
                  @click="
                    toggleInput('edittitle_column2', 'city-title_column2-input')
                  "
                >
                  {{ editedTexts.columnsSection.title_column2 }}
                </h4>
                <v-text-field
                  v-show="edittitle_column2"
                  ref="city-title_column2-input"
                  v-model="editedTexts.columnsSection.title_column2"
                  solo
                  dense
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="saveColumns"
                  @blur="saveColumns"
                />
              </template>
              <template v-else>
                <h4 class="primary--text text-xs-center mb-3">
                  {{ editedTexts.columnsSection.title_column2 }}
                </h4>
              </template>
              <template
                v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
              >
                <p
                  v-if="
                    editedTexts.columnsSection.info_column2.trim().length ===
                      0 && !editinfo_column2
                  "
                  class="subheading text-xs-center link"
                  @click="
                    toggleInput('editinfo_column2', 'city-infocol2-input')
                  "
                >
                  Agrega la información de ésta columna de la Ciudad
                </p>
                <p
                  v-if="
                    editedTexts.columnsSection.info_column2.trim().length !==
                      0 && !editinfo_column2
                  "
                  class="subheading text-xs-center edit"
                  @click="
                    toggleInput('editinfo_column2', 'city-infocol2-input')
                  "
                >
                  {{ editedTexts.columnsSection.info_column2 }}
                </p>
                <v-text-field
                  v-show="editinfo_column2"
                  ref="city-infocol2-input"
                  v-model="editedTexts.columnsSection.info_column2"
                  solo
                  dense
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="saveColumns"
                  @blur="saveColumns"
                />
              </template>
              <template v-else>
                <p class="subheading text-xs-center">
                  {{ editedTexts.columnsSection.info_column2 }}
                </p>
              </template>
            </v-flex>
            <v-flex xs12 md3>
              <h2 class="text-xs-center mb-3">
                <v-icon size="48" color="primary">
                  $vuetify.icons.oscity_city_hall
                </v-icon>
              </h2>
              <!-- ColumnSection: Edición de Titulo e Info Columna 3 -->
              <template
                v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
              >
                <h4
                  v-if="
                    editedTexts.columnsSection.title_column3.trim().length ===
                      0 && !edittitle_column3
                  "
                  class="primary--text text-xs-center mb-3 link"
                  @click="
                    toggleInput('edittitle_column3', 'city-title_column3-input')
                  "
                >
                  Agrega el título de esta columna de la Ciudad
                </h4>
                <h4
                  v-if="
                    editedTexts.columnsSection.title_column3.trim().length !==
                      0 && !edittitle_column3
                  "
                  class="primary--text text-xs-center mb-3 edit"
                  @click="
                    toggleInput('edittitle_column3', 'city-title_column3-input')
                  "
                >
                  {{ editedTexts.columnsSection.title_column3 }}
                </h4>
                <v-text-field
                  v-show="edittitle_column3"
                  ref="city-title_column3-input"
                  v-model="editedTexts.columnsSection.title_column3"
                  solo
                  dense
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="saveColumns"
                  @blur="saveColumns"
                />
              </template>
              <template v-else>
                <h4 class="primary--text text-xs-center mb-3">
                  {{ editedTexts.columnsSection.title_column3 }}
                </h4>
              </template>
              <template
                v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
              >
                <p
                  v-if="
                    editedTexts.columnsSection.info_column3.trim().length ===
                      0 && !editinfo_column3
                  "
                  class="subheading text-xs-center link"
                  @click="
                    toggleInput('editinfo_column3', 'city-infocol3-input')
                  "
                >
                  Agrega la información de ésta columna de la Ciudad
                </p>
                <p
                  v-if="
                    editedTexts.columnsSection.info_column3.trim().length !==
                      0 && !editinfo_column3
                  "
                  class="subheading text-xs-center edit"
                  @click="
                    toggleInput('editinfo_column3', 'city-infocol3-input')
                  "
                >
                  {{ editedTexts.columnsSection.info_column3 }}
                </p>
                <v-text-field
                  v-show="editinfo_column3"
                  ref="city-infocol3-input"
                  v-model="editedTexts.columnsSection.info_column3"
                  solo
                  dense
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="saveColumns"
                  @blur="saveColumns"
                />
              </template>
              <template v-else>
                <p class="subheading text-xs-center">
                  {{ editedTexts.columnsSection.info_column3 }}
                </p>
              </template>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container fluid class="pa-0">
      <v-layout row wrap>
        <v-flex xs12 text-xs-center mt-5>
          <v-parallax
            :src="this.$store.state.brand.brand.city_splash_secondary"
            class="last"
            height="450"
          >
            <v-container fill-height>
              <v-layout row wrap align-center justify-center hidden-xs-only>
                <v-flex xs12 sm8>
                  <template
                    v-if="
                      $auth && $auth.user && $auth.user.type === 'superadmin'
                    "
                  >
                    <h1
                      v-if="
                        editedTexts.reminderSection.reminderTitle.trim()
                          .length === 0 && !editReminderTitle
                      "
                      class="white--text display-2 link"
                      @click="
                        toggleInput(
                          'editReminderTitle',
                          'city-reminderTitle-input'
                        )
                      "
                    >
                      Agrega el texto de Recordatorio
                    </h1>
                    <h1
                      v-if="
                        editedTexts.reminderSection.reminderTitle.trim()
                          .length !== 0 && !editReminderTitle
                      "
                      class="white--text display-2 edit"
                      @click="
                        toggleInput(
                          'editReminderTitle',
                          'city-reminderTitle-input'
                        )
                      "
                    >
                      {{ editedTexts.reminderSection.reminderTitle }}
                    </h1>
                    <v-text-field
                      v-show="editReminderTitle"
                      ref="city-reminderTitle-input"
                      v-model="editedTexts.reminderSection.reminderTitle"
                      solo
                      dense
                      dark
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="saveReminder"
                      @blur="saveReminder"
                    />
                  </template>
                  <template v-else>
                    <h1 class="white--text display-2">
                      {{ editedTexts.reminderSection.reminderTitle }}
                    </h1>
                  </template>
                  <!-- Reminder: Edición de Reminder Text -->
                  <template
                    v-if="
                      $auth && $auth.user && $auth.user.type === 'superadmin'
                    "
                  >
                    <h4
                      v-if="
                        editedTexts.reminderSection.reminderText.trim()
                          .length === 0 && !editReminderText
                      "
                      class="white--text headline link"
                      @click="
                        toggleInput(
                          'editReminderText',
                          'city-reminderText-input'
                        )
                      "
                    >
                      Agrega el texto de Recordatorio
                    </h4>
                    <h4
                      v-if="
                        editedTexts.reminderSection.reminderText.trim()
                          .length !== 0 && !editReminderText
                      "
                      class="white--text headline edit"
                      @click="
                        toggleInput(
                          'editReminderText',
                          'city-reminderText-input'
                        )
                      "
                    >
                      {{ editedTexts.reminderSection.reminderText }}
                    </h4>
                    <v-text-field
                      v-show="editReminderText"
                      ref="city-reminderText-input"
                      v-model="editedTexts.reminderSection.reminderText"
                      solo
                      dense
                      dark
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="saveReminder"
                      @blur="saveReminder"
                    />
                  </template>
                  <template v-else>
                    <h4 class="white--text headline">
                      {{ editedTexts.reminderSection.reminderText }}
                    </h4>
                  </template>
                </v-flex>
              </v-layout>
              <!-- Version Mobile del Reminder -->
              <v-layout row wrap align-center justify-center hidden-sm-and-up>
                <v-flex xs12 sm8>
                  <h1 class="white--text display-1">
                    {{ this.$store.state.brand.brand.city_slogan }}
                  </h1>
                  <h4 class="white--text title">
                    La utilización de blockchain es un paso más en el camino
                    hacia un arte innovador, que busca mejorar cotidianamente.
                  </h4>
                </v-flex>
              </v-layout>
            </v-container>
          </v-parallax>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs12 text-xs-center mt-5 mb-5>
          <!-- <h1 class="mb-0 font-weight-light grey--text text--darken-2">
            Ruth Benzacar Galería de Arte
          </h1> -->
          <template
            v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
          >
            <h1
              v-if="
                editedTexts.hookSection.hook_title.trim().length === 0 &&
                  !edithook_title
              "
              class="mb-0 font-weight-light grey--text text--darken-2 link"
              @click="toggleInput('edithook_title', 'city-hook_title-input')"
            >
              Agrega el título de Busqueda de Certificados
            </h1>
            <h1
              v-if="
                editedTexts.hookSection.hook_title.trim().length !== 0 &&
                  !edithook_title
              "
              class="mb-0 font-weight-light grey--text text--darken-2 edit"
              @click="toggleInput('edithook_title', 'city-hook_title-input')"
            >
              {{ editedTexts.hookSection.hook_title }}
            </h1>
            <v-text-field
              v-show="edithook_title"
              ref="city-hook_title-input"
              v-model="editedTexts.hookSection.hook_title"
              solo
              dense
              @keydown.enter.exact.prevent
              @keyup.enter.exact="saveHook"
              @blur="saveHook"
            />
          </template>
          <template v-else>
            <h1 class="mb-0 font-weight-light grey--text text--darken-2">
              {{ editedTexts.hookSection.hook_title }}
            </h1>
          </template>
          <!-- <h3 class="mt-0" style="color:#5b5b5b">
            Promoviendo el Desarrollo Armónico del Arte
          </h3> -->
          <template
            v-if="$auth && $auth.user && $auth.user.type === 'superadmin'"
          >
            <h3
              v-if="
                editedTexts.hookSection.hook_text.trim().length === 0 &&
                  !edithook_text
              "
              class="mt-0 link"
              style="color:#5b5b5b"
              @click="toggleInput('edithook_text', 'city-hook_text-input')"
            >
              Agrega el título de Busqueda de Certificados
            </h3>
            <h3
              v-if="
                editedTexts.hookSection.hook_text.trim().length !== 0 &&
                  !edithook_text
              "
              class="mt-0 edit"
              style="color:#5b5b5b"
              @click="toggleInput('edithook_text', 'city-hook_text-input')"
            >
              {{ editedTexts.hookSection.hook_text }}
            </h3>
            <v-text-field
              v-show="edithook_text"
              ref="city-hook_text-input"
              v-model="editedTexts.hookSection.hook_text"
              solo
              dense
              @keydown.enter.exact.prevent
              @keyup.enter.exact="saveHook"
              @blur="saveHook"
            />
          </template>
          <template v-else>
            <h3 class="mt-0" style="color:#5b5b5b">
              {{ editedTexts.hookSection.hook_text }}
            </h3>
          </template>
        </v-flex>
        <v-flex
          v-if="!$auth.loggedIn"
          xs12
          text-xs-center
          mb-5
          hidden-md-and-up
        >
          <nuxt-link to="login">
            <v-btn color="primary">
              INGRESAR
            </v-btn>
          </nuxt-link>
          <nuxt-link to="register">
            <v-btn color="primary">
              CREAR CUENTA
            </v-btn>
          </nuxt-link>
        </v-flex>
        <!-- <v-flex v-else xs12 text-xs-center mb-5 hidden-md-and-up>
          <nuxt-link to="/citizen/listener/create-v1">
            <v-btn color="primary">
              Comenzar
            </v-btn>
          </nuxt-link>
        </v-flex> -->
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import _ from 'lodash'
import LandingTextService from '@/services/LandingTextService.js'

export default {
  data() {
    return {
      selectedHash: null,
      isLoading: false,
      validCertificate: false,
      rules: [v => !!v || 'El campo es requerido'],
      editTitle: false,
      editSubtitle: false,
      editSecondSubtitle: false,
      editcertificate_title: false,
      editCitySlogan: false,
      edittitle_column1: false,
      edittitle_column2: false,
      edittitle_column3: false,
      editinfo_column1: false,
      editinfo_column2: false,
      editinfo_column3: false,
      editReminderText: false,
      editReminderTitle: false,
      edithook_title: false,
      edithook_text: false,
      editedTexts: {
        heroSection: {
          title: '',
          subtitle: '',
          second_subtitle: '',
          certificate_title: ''
        },
        columnsSection: {
          slogan: '',
          title_column1: '',
          title_column2: '',
          title_column3: '',
          info_column1: '',
          info_column2: '',
          info_column3: ''
        },
        reminderSection: {
          reminderTitle: '',
          reminderText: ''
        },
        hookSection: {
          hook_title: '',
          hook_text: ''
        }
      },
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: ''
      }
    }
  },
  mounted() {
    this.$store.commit('SET_HOME_TOP', true)
    if (this.$store.state.landingtext.landingtext) {
      this.editedTexts = _.cloneDeep(this.$store.state.landingtext.landingtext)
      if (!this.editedTexts.id) this.createLandingText()
    }
  },
  destroyed() {
    this.$store.commit('SET_HOME_TOP', false)
  },
  methods: {
    onScroll(e) {
      if (
        e &&
        e.target &&
        e.target.scrollingElement &&
        e.target.scrollingElement.scrollTop >= 10
      ) {
        this.$store.commit('SET_HOME_TOP', false)
      } else {
        this.$store.commit('SET_HOME_TOP', true)
      }
    },
    async toggleInput(field, element) {
      this[field] = true
      await this.$nextTick()
      // Focus on the deepest node (input) of the vuetify element
      this.$refs[element].focus()
    },
    createLandingText() {
      this.$wait.start('createTexts')
      const flatTextValues = {
        ...this.editedTexts.heroSection,
        ...this.editedTexts.columnsSection,
        ...this.editedTexts.reminderSection,
        ...this.editedTexts.hookSection
      }
      LandingTextService.create(flatTextValues)
        .then(res => {
          this.$store.commit('landingtext/SET_LANDINGTEXT', res)
          this.editedTexts = _.cloneDeep(
            this.$store.state.landingtext.landingtext
          )
        })
        .catch(err => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
          this.$wait.end('createTexts')
        })
        .finally(() => {
          this.$wait.end('createTexts')
        })
    },
    saveHero() {
      this.$wait.start('hero')
      this.editedTexts.heroSection.id = this.editedTexts.id
      LandingTextService.updateHero(this.editedTexts.heroSection)
        .catch(err => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
          this.$wait.end('hero')
        })
        .finally(() => {
          this.$wait.end('hero')
        })
      this.editTitle = false
      this.editSubtitle = false
      this.editSecondSubtitle = false
      this.editcertificate_title = false
    },
    saveColumns() {
      this.$wait.start('columns')
      this.editedTexts.columnsSection.id = this.editedTexts.id
      LandingTextService.updateColumns(this.editedTexts.columnsSection)
        .catch(err => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
          this.$wait.end('columns')
        })
        .finally(() => {
          this.$wait.end('columns')
        })
      this.editCitySlogan = false
      this.edittitle_column1 = false
      this.edittitle_column2 = false
      this.edittitle_column3 = false
      this.editinfo_column1 = false
      this.editinfo_column2 = false
      this.editinfo_column3 = false
    },
    saveReminder() {
      this.$wait.start('reminder')
      this.editedTexts.reminderSection.id = this.editedTexts.id
      LandingTextService.updateReminder(this.editedTexts.reminderSection)
        .catch(err => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
          this.$wait.end('reminder')
        })
        .finally(() => {
          this.$wait.end('reminder')
        })
      this.editReminderText = false
      this.editReminderTitle = false
    },
    saveHook() {
      this.$wait.start('hook')
      this.editedTexts.hookSection.id = this.editedTexts.id
      LandingTextService.updateHook(this.editedTexts.hookSection)
        .catch(err => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
          this.$wait.end('hook')
        })
        .finally(() => {
          this.$wait.end('hook')
        })
      this.edithook_title = false
      this.edithook_text = false
    },
    viewCertificate() {
      if (this.$refs.formCertificate.validate()) {
        window.open(
          `https://certificados.upso.edu.ar/${this.selectedHash}`,
          '_blank'
        )
        this.$refs.formCertificate.reset()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
>>>.v-parallax.first {
  box-shadow 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  .v-parallax__image {
    filter: brightness(45%);
  }
  .v-parallax__content {
    h1 {
      font-weight 900
      line-height 1 !important
    }
  }
}
>>>.v-parallax.last {
  box-shadow 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  .v-parallax__image {
    filter brightness(45%)
  }
  .v-parallax__content {
    h1 {
      font-weight 700
      margin 0 2.5rem 2.5rem
    }
    h4 {
      font-weight 400
      line-height !important
    }
  }
}
h1 {
  font-size 3rem
  font-weight 100
  span {
    font-weight 900
  }
}
h2 {
  font-weight 700
  i {
    font-size 3.2rem
  }
}
h3 {
  font-size 2.5rem
}
h4 {
  font-size 1.7rem
}
.link_noLine {
  text-decoration: none;
}
.hero-container {
  margin-top: 5%;
}
.input-search {
  background-color: white;
  border-radius: 10px;
}
.btn-search {
  height: 48px;
  border-radius: 4px;
}
@media only screen and (min-width: 1264px) {
  .container-info__columns{
    max-width: 900px !important;
  }
}
.see-certificate {
  border: 1px solid white;
}

>>>.theme--light.v-btn.v-btn--disabled {
  color: white !important;
}
</style>
